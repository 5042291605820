<template>
    <div style="min-height: 100vh;">
        <b-container>
            <div class="default_box">
                <div class="default_header">
                    <h1>Выбор города</h1>
                </div>
                <div class="default_box_content bg_white p15" v-if="city_list">
                    <input type="text" class="main_input" v-model="inputSearch" placeholder="Введите название города или региона">
                    <div v-if="filters && filters.length">
                        <router-link v-for="(item, index) of filters" v-bind:key="index" v-bind:to="{name:'Home',params: { city:item.index }}">
                            <div class="city_item">
                                <p class="city_name">{{ item.name }}</p>
                                <p class="city_reg">{{ item.federal_subject }}, <span>{{ item.country_name }}</span>
                                </p>
                            </div>
                        </router-link>
                    </div>
                    <div v-else>
                        <div class="city_item" style="border-bottom: 0;">
                            <p class="city_reg">К сожалению мы не смогли найти этот город. Возможно Вы допустили ошибку или этот город ещё не подключен к "Окей Город"
                            </p>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <PreLoadBox />
                </div>
            </div>
        </b-container>
    </div>
</template>
<script>
export default {
    name: "CityList",
    metaInfo() {
        return {
            title: 'Выбор города - Окей Город',
            meta: [
                { name: 'description', itemprop: 'description', content: 'Новости города, Афиша, Акции, Справочник организаций, Киноафиша и многое другое' },
                { property: 'og:url', content: this.$config.url + this.$route.fullPath },
                { property: 'og:title', content: 'Выбор города - Окей Город' },
                { property: 'og:description', content: 'Новости города, Афиша, Акции, Справочник организаций, Киноафиша и многое другое' },
                { property: 'og:image', content: 'https://okaygorod.com/images/logoHD.png' },
                { property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            city_list: null,
            inputSearch: '',
        };
    },
    mounted() {
        let ms;
        if (this.$cityList.length) {
            ms = 0
        } else {
            ms = 1000;
        }
        setTimeout(() => {
            this.makeList();
        }, ms);


    },
    methods: {
        makeList() {
            const list = this.$cityList;
            this.city_list = Object.values(list).sort(function(a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            })
        },
    },
    computed: {
        filters() {
            if (this.inputSearch) {
                return Object.values(this.city_list).filter(item => {
                    if (item.name.toLowerCase().includes(this.inputSearch.toLowerCase())) {
                        return item;
                    }

                    if (item.federal_subject.toLowerCase().includes(this.inputSearch.toLowerCase())) {
                        return item;
                    }

                    if (item.index.toLowerCase().includes(this.inputSearch.toLowerCase())) {
                        return item;
                    }
                });
            }
            return this.city_list
        },

    },
}
</script>
<style scoped>
@media only screen and (max-width: 767.9px) {
    .container {
        padding: 0;
    }
}

.default_box {
    max-width: 600px;
    margin: 0 auto;
}


a {
    text-decoration: none;
    color: #2c3e50;
}

.city_item {
    padding: 5px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.city_item:hover {
    background: rgba(var(--main-color), .05);
    transition: var(--main-transition);
    color: #2c3e50;
}

.default_box_content a:last-child .city_item {
    border-bottom: 0px;
}

.city_name {
    font-size: 20px;
    margin-bottom: 0;
    font-weight: bold;
}

.city_reg {
    color: #757575;
}
</style>